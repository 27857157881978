<template>
  <small
    v-if="form && form.errors.hasAny(field_name)"
    slot="helperText"
    class="form-text text-muted"
    >{{ form.errors.get(field_name) }}</small
  >
</template>

<script>
export default {
  name: 'HasErrorForm',
  props: ['form', 'field_name']
}
</script>
